import React from "react";
import Modal from 'react-modal';
import baseURL from "../../Components/url";
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Reservas.css'



const Reservas = () => {
	const [mesas, setMesas] = useState([]);
	const [name, setName] = useState('');
	const [codigo, setCodigo] = useState('');
	const [noteText, setNoteText] = useState('');
	const [selectedMesa, setSelectedMesa] = useState('');
	const [idMesa, setIdMesa] = useState('');
	const [cartItems, setCartItems] = useState([]);
	const [mensaje, setMensaje] = useState('')




	const obtenerImagen = (item) => {
		return item.imagen1 || item.imagen2 || item.imagen3 || item.imagen4 || null;
	};

	const cargarMesas = () => {
		fetch(`${baseURL}/mesaGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setMesas(data.mesas || []);
			})
			.catch(error => console.error('Error al cargar mesas:', error));
	};
	

	useEffect(() => {
		cargarMesas();
	}, []);

	return (
		<div>
			<div className='deFLex'>
				<h4>Elige tu mesa</h4>
			</div>
			<div className="modal-send-form">
				<input
					type="text"
					id="name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder='Nombre'
				/>
				<input
					type="text"
					id="codigo"
					value={codigo}
					onChange={(e) => setCodigo(e.target.value)}
					placeholder='Codigo de descuento (opcional)'
				/>
				<textarea
					placeholder="Agrega una nota (opcional)"
					value={noteText}
					onChange={(e) => setNoteText(e.target.value)}
				/>
				<div className='mesasGrapCart'>
					{mesas.map(item => (
						<div
							key={item.idMesa}
							className={`mesaCard ${item.estado === 'libre' ? (selectedMesa === item.idMesa ? 'selectedMesa' : 'bg-green') : 'bg-red'}`}
							onClick={() => { if (item.estado === 'libre') setIdMesa(item.idMesa) }}
						>
							<label>
								{item.mesa}
							</label>
							<span>
								{item.estado === 'libre' ? (selectedMesa === item.idMesa ? 'selectedMesa' : '') : 'ocupada'}
							</span>
							{item.estado === 'libre' && (
								<input
									type='radio'
									name='productos'
									value={item.idMesa}
									readOnly
									checked={idMesa === item.idMesa}
								/>
							)}
						</div>
					))}
				</div>

				<fieldset className='deNonefieldset'>
					<legend>Productos</legend>
					<textarea
						name='productos'
						value={cartItems.map(item => ` ${item.categoria}, ${item.titulo}, x ${item.cantidad}, ${item.item},${item.categoria},${item.precio}, ${obtenerImagen(item)}  `).join('\n')}
						readOnly
					/>
				</fieldset>
				{mensaje ? (
					<button type='button' className='btn' disabled>
						{mensaje}
					</button>
				) : (
					<button type='button' className='btn'>
						Finalizar pedido
					</button>
				)}
			</div>
		</div>
	);
};




export default Reservas;
