import React, { useState, useEffect } from 'react';
import Spiner from '../Components/Admin/Spiner/Spiner';
import { Outlet } from 'react-router-dom';
import Auth from '../Components/Admin/Auth/Auth';
import baseURL from '../Components/url';
export default function MainLayout() {
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            
                const usaurioLS = window.localStorage.getItem('userLogued')
                const usuarioTemporal = JSON.parse(usaurioLS)
                if(usuarioTemporal != {}){
                    setUsuario(usuarioTemporal);
                    setLoading(false);
                } else {

                    const response = await fetch(`${baseURL}/userLogued.php`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                     setUsuario(data);
                     setLoading(false);
                }
         
        } catch (error) {
            console.error('Error al obtener datos:', error);
            setLoading(false);
        }
       
    };
    useEffect(() => {

        fetchData();
    }, []);
    useEffect(() => {

         console.log(usuario)
    }, [usuario]);
    
    return (
        <div>
            <div>
                {loading ? (
                    <Spiner />
                ) : usuario?.idUsuario ? (
                    <>
                       {usuario.nombre == 'admin' ? (
                            <Outlet />
                        ) : (
                            <Auth />
                        )}
                    </>
                ) : (
                    <Auth />
                )}
            </div>

        </div>
    );
}
