export const activeItems = ( items ) => {
    return (items.filter(i => (i?.estado != 'Entregado') && (i?.estado != 'Rechazado') ))
}
export const getLetterMonth = ( month ) =>{
    switch(month){
        case 1 : return 'Enero' ;
        case 2 : return 'Febrero' ;
        case 3 : return 'Marzo' ;
        case 4 : return 'Abril' ;
        case 5 : return 'Mayo' ;
        case 6 : return 'Junio' ;
        case 7 : return 'Julio' ;
        case 8 : return 'Agosto' ;
        case 9 : return 'Septiembre' ;
        case 10 : return 'Octubre' ;
        case 11 : return 'Noviembre' ;
        case 12 : return 'Diciembre' ;
        default: return 'Mes'
    }
}

export const formatedDate = ( date ) =>{
    const fecha = new Date(date)
    const actual = new Date()
    let dia = fecha.getDay()
    let mes = fecha.getMonth()
    let ano = fecha.getFullYear()
    let horas = fecha.getHours()
    let minutos = fecha.getMinutes()
    let segundos = fecha.getSeconds()
    dia = dia < 10 ? `0${dia}` : dia
    mes = mes < 10 ? `0${mes}` : mes
    horas = horas < 10 ? `0${horas}` : horas
    minutos = minutos < 10 ? `0${minutos}` : minutos
    segundos = segundos < 10 ? `0${segundos}` : segundos

    if(ano == actual.getFullYear()){ 
        if(mes == actual.getMonth()){ 
            if(dia == actual.getDay()){
               return (`Hoy ${horas}:${minutos}:${segundos}`)
            } else if ( dia <  actual.getDay()){
                return (`Esta semana ${horas}:${minutos}`)
            }
        } else if(mes == actual.getMonth() - 1 ) {
            return (`Hace un mes ${horas}:${minutos}:${segundos}`)
        } else {
            return (`${dia} ${ getLetterMonth(parseInt(mes) ) } ${horas}:${minutos}`)
        }
    } else {
        return (`${ano} ${ getLetterMonth(parseInt(mes)) } ${horas}:${minutos}`)
    }
    return date
}