import React, { useState } from 'react';
import './HeaderDash.css';
import ButonScreen from '../ButonScreen/ButonScreen';
import InputSearch from '../InputSearch/InputSearch';
import InfoUser from '../InfoUser/InfoUser';
import ButonInstallAppNav from '../ButonInstallAppNav/ButonInstallAppNav'
export default function HeaderDash() {
    const [ accessLink, setAccessLink ] = useState(false)
    const [ infoUser, setInfoUser ] = useState(false)

    return (
        <div className={`HeaderDashContain px-10`}>
            <InputSearch />

            <div className='deFlexHeader'>
                 
                <ButonScreen /> 
                { accessLink&&
                    <ButonInstallAppNav />
                }
                {
                    infoUser &&
                    <InfoUser />
                }
            </div>


        </div>
    );
}
