import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './SucursalesData.css'
import 'jspdf-autotable';
import baseURL from '../../url';
import NewContact from '../NewContact/NewContact';
export default function SucursalesData() {
    const [sucursales, setSucursales] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const [nuevoNombre, setNuevoNombre] = useState('');
    const [nuevoAlias, setNuevoAlias] = useState('');
    const [nuevoTelefono, setNuevoTelefono] = useState('');
    const [nuevoHorarios, setNuevoHorarios] = useState('');
    const [nuevoEntrega, setNuevoEntrega] = useState(1);
    const [nuevoTiempo, setNuevoTiempo] = useState(20);
    const [nuevoMesas, setNuevoMesas] = useState(1);
    const [nuevoEmail, setNuevoEmail] = useState('');
    const [nuevaDireccion, setNuevaDieccion] = useState('');  
    const [nuevaImagen, setNuevaImagen] = useState(null);
    const [imagenPreview, setImagenPreview] = useState(null);
    const [nuevaImagenFile, setNuevaImagenImagenFile] = useState(null);
    const [modalImagenVisible, setModalImagenVisible] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState('');
    const [sucursal, setSucursal] = useState({});

    const [selectedSection, setSelectedSection] = useState('texto');
  

    useEffect(() => {
        cargarSucursales(); 
    }, []);

    const cerrarModalImagen = () => {
        setModalImagenVisible(false);
    };
    const abrirModalImagenSeleccionada = (imagen) => {
        setImagenSeleccionada(imagen);
        setModalImagenVisible(true);
    };

    const handleFileChange = (event, setFile, setPreview) => {
        const file = event.target.files[0];

        if (file) {
            // Crear una URL de objeto para la imagen seleccionada
            const previewURL = URL.createObjectURL(file);
            setFile(file);
            setPreview(previewURL);
        }
    };
    const handleEditarImagenBanner = (idSucursal) => {
        const formData = new FormData();
        formData.append('idSucursal', idSucursal);
        formData.append('updateAction', 'update'); // Campo adicional para indicar que es una actualización

        if (nuevaImagenFile) {
            formData.append('imagen', nuevaImagenFile);
        }
          
        fetch(`${baseURL}/sucursalImagePut.php`, {
            method: 'POST',  // Cambiado a POST
            body: formData
        })
            .then(response => {
                // Manejar el caso cuando la respuesta no es un JSON válido o está vacía
                if (!response.ok) {
                    throw new Error('La solicitud no fue exitosa');

                }

                return response.json();
            })
            .then(data => {
                if (data.error) {

                    toast.error(data.error);
                    console.log(formData)
                } else {

                    toast.success(data.mensaje);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error)
                toast.error(error.message);
                console.log(formData)
                console.log(idSucursal)
            });
    };
    const cargarSucursales = () => {
        fetch(`${baseURL}/sucursalesGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setSucursales(data.sucursales || []);
                console.log(data.sucursales)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const eliminarSucursal = (idSucursal) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/sucursalesDelete.php?idSucursal=${idSucursal}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarSucursales();
                    })
                    .catch(error => {
                        console.error('Error al eliminar la sucursal:', error);
                        toast.error(error);
                    });
            }
        });
    };



    const abrirModal = (item) => { 

        setSucursal(item);
        setNuevoNombre(item.nombre);
        setNuevoAlias(item.alias)
        setNuevoTelefono(item.telefono);
        setNuevoHorarios(item.horarios) 
        setNuevoEntrega(item.entrega);
        setNuevoTiempo(item['tiempo_entrega']);
        setNuevoMesas(item.mesas)
        setNuevoEmail(item.email);
        setNuevaDieccion(item.direccion);
        setNuevaImagen(item.imagen);
        setModalVisible(true);

    };

    const cerrarModal = () => {
        setModalVisible(false);
    };



    const handleUpdateText = (idSucursal) => {
        const payload = {
            nombre: nuevoNombre !== '' ? nuevoNombre : sucursal.nombre,
            alias: nuevoAlias !== '' ? nuevoAlias : sucursal.alias,
            telefono: nuevoTelefono !== '' ? nuevoTelefono : sucursal.telefono,
            horarios: nuevoHorarios !== '' ? nuevoHorarios : sucursal.horarios,
            entrega: nuevoEntrega !== '' ? nuevoEntrega : sucursal.entrega,
            tiempo: nuevoTiempo !== '' ? nuevoTiempo : sucursal['tiempo_entrega'],
            mesas: nuevoMesas !== '' ? nuevoMesas : sucursal.mesas, 
            email: nuevoEmail !== '' ? nuevoEmail : sucursal.email,
            direccion: nuevaDireccion !== '' ? nuevaDireccion : sucursal.direccion,
            imagen: nuevaImagen !== '' ? nuevaImagen : sucursal.imagen
        };

        fetch(`${baseURL}/sucursalesPut.php?idSucursal=${idSucursal}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {
                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarSucursales();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };


    const onlyLetter = ( text ) => { 

        return text[0]
    } 
    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };
    return (
        <div>

            <ToastContainer />

            <NewContact />
 

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>

                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Sucursal
                                </button>

                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                
                                
                                {
                                    (nuevaImagen !== '' ? nuevaImagen : sucursal.imagen) !== '' && 
                                    <img  
                                        src={nuevaImagen !== '' ? nuevaImagen : sucursal.imagen} 
                                        alt={(sucursal.nombre)} 
                                        height={50}
                                        width={50}
                                    />
                                }  
                                
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        value={nuevoNombre !== '' ? nuevoNombre : sucursal.nombre}
                                        onChange={(e) => setNuevoNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Alias</legend>
                                    <input
                                        type="text"
                                        value={nuevoAlias !== '' ? nuevoAlias : sucursal.alias}
                                        onChange={(e) => setNuevoAlias(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Telefono</legend>
                                    <input
                                        type="number"
                                        value={nuevoTelefono !== '' ? nuevoTelefono : sucursal.telefono}
                                        onChange={(e) => setNuevoTelefono(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Horarios</legend>
                                    <input
                                        type="text"
                                        value={nuevoHorarios !== '' ? nuevoHorarios : sucursal.horarios}
                                        onChange={(e) => setNuevoHorarios(e.target.value)}
                                    />
                                </fieldset>
                                <div className='flex gap-5 items-center'>
                                    <legend>Hace Entrega</legend> 
                                    <select  
                                        defaultValue={ nuevoEntrega != 0 ? nuevoEntrega : sucursal.entrega }
                                        onChange={(e) => setNuevoEntrega(e.target.value)}
                                        >
                                        <option value={0}> No </option>
                                        <option value={1}>  Si </option>
                                    </select>
                                    
                                </div>
                                <fieldset>
                                    <legend>Tiempo Entregas (min)</legend>
                                    <input
                                        type="number"
                                        value={nuevoTiempo !== '' ? nuevoTiempo : sucursal.tiempo_entrega}
                                        onChange={(e) => setNuevoTiempo(e.target.value)}
                                    />
                                </fieldset>
                                <div className='flex gap-5 items-center'>
                                    <legend>Tiene Mesas</legend>
                                    <select  
                                        defaultValue={ nuevoMesas != 0 ? nuevoMesas : sucursal.mesas }
                                        onChange={(e) => setNuevoMesas(e.target.value)}
                                        >
                                        <option value={0}> No </option>
                                        <option value={1}>  Si </option>
                                    </select>
                                     
                                </div>
                                <fieldset >
                                    <legend>email</legend>
                                    <input
                                        type="email"
                                        value={nuevoEmail !== '' ? nuevoEmail : sucursal.email}
                                        onChange={(e) => setNuevoEmail(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>Direccion</legend>
                                    <input
                                        type="text"
                                        value={nuevaDireccion !== '' ? nuevaDireccion : sucursal.direccion}
                                        onChange={(e) => setNuevaDieccion(e.target.value)}
                                    />
                                </fieldset>
                            </div>




                            <button className='btnPost' onClick={() => handleUpdateText(sucursal.id)} >Guardar </button>

                        </div>




                    </div>
                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className='w-[100px]'>Activo</th>
                            <th> {/* imagen */}</th> 
                            <th>Nombre</th>
                            <th>Alias</th>
                            <th>Telefono</th>
                            <th>Horarios</th>
                            <th>Entrega</th>
                            <th>Tiempo entrega</th>
                            <th>Mesas</th>
                            <th>Email</th>
                            <th>Direccion</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sucursales.map(item => (
                            <tr key={item.id} className='text-left'>
                                <td >
                                    <input className='w-5 h-5' type="checkbox" checked={item.activo == 1} data-id={item.id} /> </td>
                                <td>
                                    { item.imagen != '' ? (
                                        <div className='rounded bg-[#0001] h-20 w-20 flex items-center justify-center'>
                                            <img 
                                                src={item.imagen} 
                                                alt="imagen1" 
                                                className='min-h-20 min-w-20 ' 
                                                
                                                />
                                        </div>


                                    ) : (
                                        <span className='imgNonetd'>
                                            Sin imagen
                                        </span>
                                    )}
                                </td>
                                <td>{item.nombre}</td>
                                <td>{item.alias}</td> 
                                <td>{item.telefono}</td>
                                <td>{item.horarios}</td>
                                <td>{item.entrega == 1 ? 'Si' : 'No'}</td>
                                <td>{`${item['tiempo_entrega']} Min`}</td>
                                <td>{item.mesas == 1 ? 'Si' : 'No'}</td>
                                <td>{item.email}</td>
                                <td>{item.direccion}</td> 
                                <td> 
                                    <button className='eliminar' onClick={() => eliminarSucursal(item.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};
