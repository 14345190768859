import React, { useEffect, useState } from 'react';
import './CardsCantidad.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faImage, faAddressBook, faTachometerAlt, faCode, faTable, faClipboardList, faClock } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from "react-router-dom";
import baseURL from '../../url';
import contador from '../../contador'
import { activeItems } from '../../../Helpers/helpers';
export default function CardsCantidad() {
    const [productos, setProductos] = useState([]);
    const [banners, setBanners] = useState([]);
    const [categorias, setCategoras] = useState([]);
    const [codigos, setCodigos] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [mesas, setMesas] = useState([]);
    useEffect(() => {
        cargarProductos();
        cargarBanners();
        cargarCategoria();
        cargarCodigos();
        cargarPedidos();
        cargarMesas();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };



    const cargarBanners = () => {
        fetch(`${baseURL}/bannersGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setBanners(data.banner || []);
                console.log(data.banner)
            })
            .catch(error => console.error('Error al cargar banners:', error));
    };


    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategoras(data.categorias || []);
                console.log(data.categorias)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };


    const cargarCodigos = () => {
        fetch(`${baseURL}/codigosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCodigos(data.codigos || []);
            })
            .catch(error => console.error('Error al cargar códigos:', error));
    };
    const cargarPedidos = () => {
        fetch(`${baseURL}/pedidoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setPedidos(data.pedidos || []);
                console.log(data.pedidos)
            })
            .catch(error => console.error('Error al cargar pedidos:', error));
    };
    const cargarMesas = () => {
        fetch(`${baseURL}/mesaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setMesas(data.mesas || []);
                console.log(data.mesas)
            })
            .catch(error => console.error('Error al cargar mesas:', error));
    };

    const [counter, setCounter] = useState(contador);
    const [isPaused, setIsPaused] = useState(false);
    useEffect(() => {
        /*
        const interval = setInterval(() => {
            if (!isPaused) {
                setCounter((prevCounter) => {
                    if (prevCounter === 1) {
                        recargar();
                        return contador;
                    }
                    return prevCounter - 1;
                });
            }
        }, 5000);

        return () => clearInterval(interval);
        */
    }, [isPaused]);

    const togglePause = () => {
        setIsPaused(!isPaused);
    };


    const recargar = () => {
        cargarMesas();
        cargarPedidos();
    };

    const CardItem = ({ to, icon, title, data }) => {
        return (<Anchor to={to} className='shadow flex items-center gap-2 pl-4 h-20 min-w-[300px] hover:rounded-r-[10px]' >
            <FontAwesomeIcon icon={icon} className='icons w-1/12' />
            <div className='w-7/12'>
                <h3>{title}</h3>
            </div>
            <div className='w-5/12  flex bg-[red] text-[white] hover:text-[red] hover:bg-[white] justify-center items-center h-full p-2 rounded-r-[10px]'> 
                <span className=' font-bold  w-full h-auto text-center'>
                    {data.length < 10  ?  data.length  > 0 ?  `0${data.length}` : '-' : data.length}
                </span>
            </div>

        </Anchor>)
    }
    
    return (
        <div className='CardsCantidad'>

            <CardItem 
                to={`/dashboard/pedidos`} 
                icon={faClock} 
                title={'Pedidos en proceso'} 
                data={activeItems(pedidos)} 
                />
            <CardItem 
                to={`/dashboard/pedidos`} 
                icon={faClipboardList} 
                title={'Todos los pedidos'} 
                data={pedidos} 
                />
            <CardItem 
                to={`/dashboard/productos`} 
                icon={faBook} 
                title={'Todos los Productos'} 
                data={productos} 
                /> 
             <CardItem 
                to={`/dashboard/categorias`} 
                icon={faTachometerAlt} 
                title={'Todas las Categorias'} 
                data={categorias} 
                /> 
            <CardItem 
                to={`/dashboard/banners`} 
                icon={faImage} 
                title={'Banners Publicidad'} 
                data={banners} 
                />  
            <CardItem 
                to={`/dashboard/codigos`} 
                icon={faCode} 
                title={'Codigos Promocion'} 
                data={codigos} 
                />
            <CardItem 
                to={`/dashboard/mesas`} 
                icon={faTable} 
                title={'Mesas Registradas'} 
                data={mesas} 
                />
              
        </div>
    )
}
