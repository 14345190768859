/* eslint-disable */

import React from "react";
import { NavLink } from 'react-router-dom';
import { Zoom } from 'react-awesome-reveal';
import { useInView } from 'react-intersection-observer';
import hamburguesa from '../../images/burger-1.png';
import './home.css'; 
import Restaurants1 from "../../images/backgraund-3.jpg";
import Restaurants2 from "../../images/backgraund-2.jpg";
import Restaurants3 from "../../images/backgraund-1.jpg";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";

const Home = () => {

  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const repeater = [1,2,3,4]
 
  const Burgers = (props) => {
    return (
      <div className="w-96 xl:w-96 flex flex-col font-Montserrat justify-center items-center overflow-hidden p-4 mr-10 ml-10">
        <img className="sha" src={require(`../../images/burger-${props.imagen}.png`)} alt="foto burger" />
        <h2 className="text-xl mb-4 text-center text-white font-black">{props.nombre}</h2>
        <p className=" font-thin leading-none text-center break-words whitespace-normal">{props.descripcion}</p>
        <p className="text-lg font-semibold mt-2 text-center">{props.precio}</p>
      </div>
    );
  }


  const ScrollRepeater = ({data, text, position}) =>{
    const dataToRepeater = data || []
    return (
    <div className="w-full overflow-hidden">
      <div className={position=='left'?'scroll-left':'scroll-right'}>
      { dataToRepeater.map((item, index) =>(
            <p className="inline-block" key={`repeater-${index}`}> 
              { dataToRepeater.map((item,indexer)=>  
                (<span  key={`repeater-span-${indexer}`}>  
                  {text}
                </span>)
                  ) }
            </p>))
        } 
      </div>
    </div>)
  }
  return (
    <>
      <Navbar />

      <section ref={ref1} className="bg-chako-700">
        {inView1 && (
          <Zoom triggerOnce>
            <div className="bg-chako-700 h-screen  relative">
              <div className="absolute inset-0 flex items-center justify-center font-Montserrat font-black italic uppercase text-3xl md:text-5xl text-white pt-20">
                <div className="flex flex-col items-center justify-center w-full">
                  <ScrollRepeater 
                    data={repeater} 
                    text={"necesitas un punch "} 
                    position={'left'} 
                    />
                  <ScrollRepeater 
                    data={repeater} 
                    text={"necesitas un punch "} 
                    position={'right'} 
                    />
                  <ScrollRepeater 
                    data={repeater} 
                    text={"necesitas un punch "} 
                    position={'left'} 
                    />
                  <ScrollRepeater 
                    data={repeater} 
                    text={"necesitas un punch "} 
                    position={'right'} 
                    /> 
                </div>
              </div>
              <div className="absolute inset-0 flex justify-center items-center">
                <img
                  src={hamburguesa}
                  alt="Hamburguesa"
                  className="max-w-full max-h-full object-contain z-10"
                />
              </div>
            </div>
            <button className="bg-white w-24s h-42 z-40"></button>
          </Zoom>
        )}
      </section>


      <section ref={ref2} className="bg-chako-700">
        {inView2 && (
          <Zoom triggerOnce>
            <div className="bg-chako-700 h-36  relative p-0">
              <div className="absolute inset-0 flex items-center justify-center font-Montserrat font-black  uppercase text-3xl md:text-5xl text-white pt-20">
                <div className="flex flex-col items-center justify-center w-full">
                    <ScrollRepeater 
                      data={repeater} 
                      text={"QUE EMPIECE EL PRIMER ROUND"} 
                      position={'right'} 
                    />
                 
                </div>
              </div>
            </div>
            <div className=" bg-chako-700 ">
              <div className=" inset-20 z-0 overflow-hidden flex items-center  text-white ">
                <div className=" flex flex-col justify-center ">
                  <div className=" overflow-hidden">
                    <div className="scroll-left-2 flex  ">
                      <Burgers
                        imagen="1"
                        nombre="ROCKY"
                        descripcion={`${<span className="font-medium">"EDICIÓN LIMITADA"</span>}250gr de carne de solomillo y entrecot la parrilla, bacón, queso cheddar, arosde cebolla, lechuga y salsa bbq`}
                        precio=" 15.5"
                      />
                      <Burgers
                        imagen="2"
                        nombre="Maravilla"
                        descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                        precio=" 15.5"
                      />
                      <Burgers
                        imagen="3"
                        nombre="TAYSON"
                        descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                      />
                      <Burgers
                        imagen="4"
                        nombre="DRAGO"
                        descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                        precio=" 15.5"
                      />
                      <Burgers
                        imagen="1"
                        nombre="ROCKY"
                        descripcion="EDICIÓN LIMITADA: 250gr de carne de solomillo y entrecot la parrilla, bacón, queso cheddar, arosde cebolla, lechuga y salsa bbq"
                        precio=" 15.5"
                      />
                      <Burgers
                        imagen="2"
                        nombre="Maravilla"
                        descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                        precio=" 15.5"
                      />
                      <Burgers
                        imagen="3"
                        nombre="TAYSON"
                        descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                      />
                      <Burgers
                        imagen="4"
                        nombre="DRAGO"
                        descripcion="250gr de carne de solomillo y entrecot la parrilla , bacón, queso cheddar, aros decebolla, lechuga y salsa bbq"
                        precio=" 15.5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Zoom>
        )}
      </section>

      <div  className="bg-chako-700 h-36  relative p-0">

              <div className="absolute inset-0 flex items-center justify-center font-Montserrat font-black  uppercase text-3xl md:text-5xl text-white pt-20">
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="overflow-hidden w-full">
                    <div className="scroll-right">
                      <p>VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS VISITANOS </p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
            
        
         
      <section ref={ref3} className="bg-chako-700">
      {inView3 && (<Zoom triggerOnce>
        <div className="flex items-center justify-center min-h-screen container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center p-5">
            <div className="card font-Montserrat font-semibold text-chako-700 bg-chako-50">
              <div className="p-5 flex flex-col">
                <div className=" overflow-hidden">
                  <img src={Restaurants1} alt="" />
                </div>
                <h5>AZUQUECA DE HENARES</h5>
                <NavLink to="/productos" className="pl-6 p-2 font-Montserrat font-semibold text-white text-center bg-chako-700 rounded-md hover:bg-chako-900">Delivery</NavLink>
              </div>
            </div>
            <div className="card font-Montserrat font-semibold  text-chako-700 bg-chako-50">
              <div className="p-5 flex flex-col gap-2">
                <div className=" overflow-hidden">
                  <img src={Restaurants2} alt="" />
                </div>
                <h5>TORREJÓN DE ARDOZ</h5>
                <NavLink to="/Reservations" className="pl-6 p-2 font-Montserrat font-semibold text-white text-center bg-chako-700 rounded-md hover:bg-chako-900">Reservar</NavLink>
                <NavLink to="/Conocenos" className="pl-6 p-2 font-Montserrat font-semibold text-white text-center bg-chako-700 rounded-md hover:bg-chako-900">Direccion</NavLink>
                <NavLink to="/productos" className="pl-6 p-2 font-Montserrat font-semibold text-white text-center bg-chako-700 rounded-md hover:bg-chako-900">Delivery</NavLink>
              </div>
            </div>
            <div className="card font-Montserrat font-semibold  text-chako-700 bg-chako-50">
              <div className="p-5 flex flex-col">
                <div className=" overflow-hidden">
                  <img src={Restaurants3} alt="" />
                </div>
                <h5>ARGANDA DEL REY</h5>
                <NavLink to="/productos" className="pl-6 p-2 font-Montserrat font-semibold text-white text-center bg-chako-700 rounded-md hover:bg-chako-900">Delivery</NavLink>
              </div>
            </div>
          </div>
        </div>
        </Zoom>)} 
      </section>
       
      <Footer/>
    </>
  );
};

export default Home;
