import React from 'react'
import Detail from '../../Components/Detail/Detail'
import './PageDetail.css'
export default function PageDetail() {
    return (
        <div className='pageDetail'>
            <Detail /> 
        </div>
    )
}
